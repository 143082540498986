// @flow

import React from "react";
import {
    AssignmentLayout,
    Layout,
    Reaction,
    withPrivateRoute,
} from "@containers";

/**
 * Reactie
 */
const Reactions = (props: *) => (
    <Layout {...props}>
        <AssignmentLayout title="Reacties">
            <Reaction />
        </AssignmentLayout>
    </Layout>
);

export default withPrivateRoute(Reactions, "/opdrachten/:inspectionId/reactie");
